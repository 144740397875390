import React from 'react'

const  Logo=()=> {
  return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="60px" height="40px" viewBox="0 0 735.000000 424.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,424.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M961 3778 c-11 -12 -26 -36 -32 -55 -13 -42 -22 -28 172 -263 162
-195 229 -285 229 -306 0 -6 -26 -29 -57 -50 -160 -105 -411 -445 -579 -786
-30 -60 -58 -107 -63 -104 -21 13 -52 103 -67 193 -8 54 -17 99 -19 101 -2 2
-21 6 -42 9 -31 4 -43 0 -65 -20 -32 -30 -46 -101 -29 -157 6 -20 11 -47 11
-59 0 -11 9 -30 20 -41 11 -11 33 -47 50 -80 17 -33 34 -60 38 -60 4 0 17 -8
29 -18 l23 -17 -34 -115 c-23 -75 -42 -120 -55 -130 -11 -8 -32 -23 -46 -33
-31 -22 -33 -66 -6 -123 16 -34 20 -70 24 -264 4 -217 5 -230 40 -366 23 -89
47 -160 68 -197 l33 -56 22 77 23 77 -35 100 c-45 128 -82 394 -58 418 4 3 32
-29 63 -73 86 -120 176 -222 255 -287 106 -88 236 -175 252 -169 8 3 14 2 14
-2 0 -10 134 -92 150 -92 8 0 23 -8 34 -18 12 -10 41 -24 66 -31 192 -56 194
-66 29 -155 -65 -35 -81 -39 -163 -43 -103 -5 -263 24 -305 55 -23 17 -26 17
-69 -5 -25 -13 -64 -23 -86 -23 l-41 0 50 -32 c104 -65 150 -80 288 -93 135
-12 197 -7 272 21 68 27 157 86 227 151 l67 63 102 0 102 0 43 36 c24 19 44
41 44 47 0 7 -34 32 -75 56 -41 25 -75 51 -75 58 0 7 16 47 35 87 45 95 96
272 131 461 28 143 29 163 29 440 0 267 -2 298 -23 394 -56 250 -109 368 -265
591 -44 63 -82 122 -84 131 -5 18 43 41 108 53 36 6 136 65 193 113 41 35 96
105 96 124 0 25 -65 49 -167 59 -107 11 -261 58 -370 113 -75 39 -166 102
-266 187 -78 66 -183 130 -214 130 -12 0 -31 -10 -42 -22z m534 -445 c50 -19
140 -48 200 -64 61 -17 114 -34 118 -38 4 -4 -4 -16 -19 -25 -39 -27 -237 -97
-245 -89 -24 25 -189 248 -189 255 0 11 19 6 135 -39z m-66 -287 c22 -23 93
-116 185 -241 62 -84 180 -315 209 -410 64 -206 86 -424 68 -690 -7 -99 -19
-225 -27 -280 -25 -170 -89 -387 -135 -456 -16 -23 -17 -23 -68 -10 -42 11
-55 11 -64 1 -8 -8 -8 -14 -2 -16 28 -11 76 -46 82 -59 3 -9 -3 -25 -13 -36
-16 -18 -26 -20 -67 -15 -178 24 -483 202 -678 396 -114 113 -190 210 -281
360 -51 82 -58 101 -58 146 0 204 231 704 474 1028 92 121 297 306 341 306 6
0 22 -11 34 -24z"/>
<path d="M5005 3647 l-40 -13 -3 -98 c-2 -76 1 -104 13 -122 23 -36 33 -256
46 -1108 12 -737 6 -1131 -20 -1415 -8 -79 -10 -86 -45 -118 -40 -38 -40 -38
-25 -82 9 -26 16 -31 44 -31 19 0 37 -5 40 -10 3 -6 24 -10 46 -10 36 0 47 6
90 51 39 40 52 49 63 40 10 -9 24 -7 57 7 61 26 167 60 275 87 91 23 194 68
194 84 0 5 32 33 70 61 175 130 259 285 260 475 0 142 -57 279 -147 350 -66
51 -162 100 -238 120 -78 20 -307 38 -379 30 -33 -3 -72 -13 -87 -21 -16 -8
-33 -14 -39 -14 -21 0 -38 66 -44 175 -12 184 19 1191 40 1328 5 30 10 37 28
37 12 0 58 9 102 21 111 29 337 24 433 -11 48 -17 76 -21 114 -17 27 4 51 8
52 9 2 2 0 21 -4 43 -6 36 -12 43 -66 72 -33 18 -67 31 -76 29 -9 -2 -45 3
-80 11 -68 15 -287 17 -414 5 -62 -7 -74 -5 -105 15 -44 28 -104 36 -155 20z
m590 -1863 c127 -31 259 -134 312 -242 51 -104 43 -246 -21 -367 -46 -87 -163
-201 -256 -248 -151 -76 -351 -101 -421 -54 -59 40 -62 59 -55 447 3 190 9
375 12 413 6 61 9 67 29 67 13 0 28 -7 35 -15 8 -10 17 -12 24 -6 35 29 232
32 341 5z"/>
<path d="M3582 3630 c-18 -4 -36 -11 -40 -15 -13 -13 -2 -199 13 -222 21 -31
33 -361 45 -1172 10 -670 4 -1038 -20 -1315 -9 -107 -10 -110 -46 -147 -33
-34 -36 -40 -29 -78 6 -35 10 -41 26 -36 11 4 33 -1 51 -9 17 -9 40 -16 50
-16 31 0 88 28 88 44 0 22 51 58 75 52 11 -3 66 11 120 30 55 19 144 46 198
60 103 25 187 62 209 92 7 9 36 34 64 55 75 54 164 153 202 225 102 191 72
450 -68 582 -61 58 -196 126 -291 146 -109 24 -393 25 -435 2 -60 -33 -75 4
-81 206 -6 176 11 874 28 1134 6 90 16 186 24 214 7 27 11 57 9 67 -12 66 -15
70 -57 90 -45 22 -79 25 -135 11z m215 -1855 c19 -13 25 -13 58 1 51 21 264
10 349 -18 175 -58 303 -202 314 -356 10 -139 -42 -259 -162 -377 -97 -96
-170 -137 -302 -171 -113 -28 -201 -31 -251 -8 -81 39 -86 80 -72 534 6 201
15 375 20 388 10 26 17 27 46 7z"/>
<path d="M6500 3629 c-30 -5 -82 -22 -115 -38 -33 -16 -73 -32 -88 -35 -37 -9
-63 -54 -78 -138 -11 -60 -10 -71 3 -85 27 -26 81 -9 125 40 51 58 166 101
268 102 67 0 72 -2 128 -43 87 -65 120 -131 156 -309 53 -257 90 -913 62
-1063 -12 -61 -50 -92 -153 -124 -107 -33 -211 -94 -321 -190 -158 -139 -275
-295 -329 -442 -20 -52 -22 -79 -23 -219 l0 -161 35 -58 c49 -81 102 -134 168
-164 48 -22 71 -27 144 -27 86 0 88 0 190 54 57 30 122 64 145 75 25 12 44 30
47 42 4 14 16 23 33 26 16 3 35 7 43 9 11 3 17 -11 24 -60 11 -78 -3 -191 -34
-276 -24 -64 -38 -214 -26 -277 5 -28 13 -37 44 -48 20 -8 40 -16 44 -18 11
-5 92 73 124 120 27 38 29 49 30 137 1 53 -5 150 -14 216 -8 66 -14 167 -13
225 0 58 1 229 0 380 0 151 4 336 9 410 8 118 13 142 36 187 31 58 28 84 -9
101 -37 17 -43 43 -44 208 -3 408 -51 1008 -92 1169 -27 101 -113 210 -201
254 -57 29 -68 31 -162 30 -56 -1 -126 -5 -156 -10z m396 -1868 c40 -24 68
-96 85 -214 15 -110 2 -206 -47 -330 -20 -51 -38 -107 -41 -125 -4 -23 -24
-54 -65 -98 -118 -126 -259 -163 -376 -99 -180 98 -241 328 -142 525 35 70
136 190 175 209 49 23 50 24 110 70 98 77 232 104 301 62z"/>
<path d="M4755 3604 c-60 -22 -78 -44 -77 -98 1 -25 6 -56 13 -67 6 -13 13
-88 15 -183 5 -211 4 -1458 -1 -1466 -2 -3 -6 -220 -9 -481 -5 -389 -8 -483
-21 -518 -23 -66 -20 -107 10 -127 24 -16 30 -16 98 5 94 28 105 39 84 83 -37
79 -50 489 -20 650 14 78 14 96 3 127 -11 29 -13 195 -11 886 2 467 6 854 9
860 4 5 10 75 14 155 4 80 8 154 10 165 3 17 -3 20 -37 22 -22 1 -58 -5 -80
-13z"/>
<path d="M2582 3589 c-37 -14 -56 -28 -63 -46 -13 -35 -11 -79 4 -111 8 -15
15 -50 16 -78 2 -46 -1 -54 -27 -75 -17 -13 -53 -37 -82 -52 l-53 -29 21 -21
c20 -20 25 -20 68 -9 59 16 71 7 79 -63 12 -89 -14 -704 -30 -729 -26 -41 -28
-100 -5 -139 22 -37 22 -37 20 -714 -2 -637 -3 -682 -22 -751 -32 -120 -5
-147 115 -112 90 25 105 38 83 73 -29 43 -35 176 -38 802 -2 459 0 617 9 637
10 22 22 29 73 39 39 7 83 26 121 50 33 22 67 39 76 39 9 0 34 14 57 30 23 17
76 53 120 82 43 28 107 79 141 112 35 34 82 79 105 101 55 52 94 110 135 198
41 90 55 156 55 255 -1 99 -23 162 -86 246 -55 74 -91 95 -246 147 -90 31 -95
31 -165 20 -40 -7 -138 -34 -218 -61 -168 -56 -162 -58 -150 53 13 129 14 122
-25 124 -19 1 -59 -7 -88 -18z m552 -228 c3 -5 22 -12 43 -16 80 -15 165 -103
200 -205 27 -83 25 -224 -7 -319 -26 -81 -98 -198 -151 -247 -95 -88 -297
-204 -355 -204 -57 0 -135 19 -166 41 l-30 22 6 391 c6 381 7 392 29 436 25
49 65 77 136 96 53 15 287 18 295 5z"/>
<path d="M777 1000 c-78 -23 -137 -116 -137 -213 0 -53 15 -82 62 -120 78 -63
204 -26 247 72 26 61 27 113 2 166 -21 46 -54 79 -94 94 -32 13 -41 13 -80 1z"/>
</g>
</svg>

  )
}

export default Logo